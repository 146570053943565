import * as React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CoachCard from '../components/CoachCard';
import AvatarMenu from '../components/AvatarMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Layout from "../components/layout"
import CoachesData from '../content/coaches.json';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SEO from "../components/seo"
import { navigate } from "gatsby"

const CoachesPage = (props) => {
  const theme = useTheme();
  const data = props.data;
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [coach, setCoach] = React.useState(props.pageContext ? props.pageContext.coachData : null);
  
  return (
  <Layout>
    <SEO title={coach ? `Coaches \u00B7 ${coach.name}` : "Coaches"} 
    image={coach ? data[coach.id+"_profile"].childImageSharp.resize : null}
    description="learn from top players with deacdes of coaching experience; raise your game!"/>
    <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
      <Grid item style={{padding: '2rem'}} xs={12}>
        <Typography variant="h3" style={{color: '#243E83'}}>Meet The Coaches</Typography>
        <Typography variant="h4"><i>Where experience intersects love of the game</i></Typography>
      </Grid>
      <Grid item style={{padding: '2rem'}} xs={12}>
        <AvatarMenu style={{justifyContent: 'center', flexWrap: 'wrap'}}>
          {Object.values(CoachesData).map((coachData) => { return (
            <Link to={`/coaches/${coachData.id}`} label={coachData.name} width={100}><GatsbyImage image={data[coachData.id+"_profile"].childImageSharp.gatsbyImageData} 
              quality={90}/></Link>)
            })
          }
        </AvatarMenu>
      </Grid>
      <Grid item xs={12} sm={8} style={{padding: '2rem'}}>
        <Typography variant="paragraph">
          As much as we love volleyball, we love helping athletess like you reach the next level and enjoy the sport. More than
          just seeking to teach, we want to enable you to understand the game on a deeper level so that you can continue to grow
          on your own and enjoy volleyball for many years to come. Not only do all of us still continue to play competitively, 
          we also have decades of coaching experience that will benefit you in achieving sucess. We are committed to helping our athletes
          pursue the sport to the highest levels and leverage our extensive volleyball networks to help with recruiting. In addition, all of us
          are <a target="_blank" href="https://uscenterforsafesport.org/">SafeSport</a> and IMPACT certified.
          Click on a profile to learn more and don't hesitate to reach out!
        </Typography>
      </Grid>
      {Object.values(CoachesData).map((coachData) => { return (
        <Modal open={coach && coach.id==coachData.id} onClose={() => { navigate('/coaches/');}} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
         <CoachCard coachKey={coachData.id} dispatch={() => navigate('/coaches/')} shareUrl={props.location.href}
            image={{portrait: data[coachData.id+"_portrait"].childImageSharp.gatsbyImageData, action: data[coachData.id+"_action"].childImageSharp.gatsbyImageData}} />
        </Modal>)
      })}
    </Grid>
  </Layout>
)}

export default CoachesPage

export const query = graphql`
  query {
    edina_blazevic_portrait: file(relativePath: {eq: "edina_blazevic_action.jpg"}) {
        ...cardImage
    }
    edina_blazevic_action: file(relativePath: {eq: "edina_blazevic_action.jpg"}) {
      ...cardImage
    }
    edina_blazevic_profile: file(relativePath: {eq: "edina_blazevic_profile.jpg"}) {
      ...cardImage
    }
    enzo_lulushi_portrait: file(relativePath: {eq: "enzo_lulushi_portrait.jpg"}) {
        ...cardImage
    }
    enzo_lulushi_action: file(relativePath: {eq: "enzo_lulushi_action.jpg"}) {
      ...cardImage
    }
    enzo_lulushi_profile: file(relativePath: {eq: "enzo_lulushi_profile.jpg"}) {
      ...cardImage
    }
    kristen_falcinelli_portrait: file(relativePath: {eq: "kristen_falcinelli_portrait.jpg"}) {
      ...cardImage
    }
    kristen_falcinelli_action: file(relativePath: {eq: "kristen_falcinelli_action.jpg"}) {
      ...cardImage
    }
    kelly_hughes_portrait: file(relativePath: {eq: "kelly_hughes_portrait.jpg"}) {
      ...cardImage
    }
    kelly_hughes_action: file(relativePath: {eq: "kelly_hughes_portrait.jpg"}) {
      ...cardImage
    }
    kristen_falcinelli_profile: file(relativePath: {eq: "kristen_falcinelli_profile.jpg"}) {
      ...cardImage
    },
    kelly_hughes_profile: file(relativePath: {eq: "kelly_hughes_profile.jpg"}) {
      ...cardImage
    }
    jordan_mckinney_portrait: file(relativePath: {eq: "jordan_mckinney_action.jpg"}) {
      ...cardImage
    }
    jordan_mckinney_action: file(relativePath: {eq: "jordan_mckinney_action.jpg"}) {
      ...cardImage
    }
    jordan_mckinney_profile: file(relativePath: {eq: "jordan_mckinney_profile.jpg"}) {
      ...cardImage
    }
  }
`